import amphitheater from "../../../dias-am/client/icons/amphitheater.svg";
import chevronDoubleDown from "../../../dias-am/client/icons/chevron-double-down.svg";
import chevronDoubleLeft from "../../../dias-am/client/icons/chevron-double-left.svg";
import chevronDoubleRight from "../../../dias-am/client/icons/chevron-double-right.svg";
import chevronDoubleUp from "../../../dias-am/client/icons/chevron-double-up.svg";
import chevronDown from "../../../dias-am/client/icons/chevron-down.svg";
import chevronLeft from "../../../dias-am/client/icons/chevron-left.svg";
import chevronRight from "../../../dias-am/client/icons/chevron-right.svg";
import chevronUp from "../../../dias-am/client/icons/chevron-up.svg";
import commentAttention from "../../../dias-am/client/icons/comment-attention.svg";
import commentText from "../../../dias-am/client/icons/comment-text.svg";
import documentEuro from "../../../dias-am/client/icons/document-euro.svg";
import documents from "../../../dias-am/client/icons/documents.svg";
import documentOk from "../../../dias-am/client/icons/document-ok.svg";
import envelope from "../../../dias-am/client/icons/envelope.svg";
import euroCircle from "../../../dias-am/client/icons/euro-circle.svg";
import request from "../../../dias-am/client/icons/request.svg";
import requests from "../../../dias-am/client/icons/requests.svg";
import thLarge from "../../../dias-am/client/icons/th-large.svg";
import time from "../../../dias-am/client/icons/time.svg";
import user from "../../../dias-am/client/icons/user.svg";

export default {
  amphitheater,
  "chevron-double-down": chevronDoubleDown,
  "chevron-double-left": chevronDoubleLeft,
  "chevron-double-right": chevronDoubleRight,
  "chevron-double-up": chevronDoubleUp,
  "chevron-down": chevronDown,
  "chevron-left": chevronLeft,
  "chevron-right": chevronRight,
  "chevron-up": chevronUp,
  "comment-text": commentText,
  "comment-attention": commentAttention,
  "document-euro": documentEuro,
  documents,
  "document-ok": documentOk,
  envelope,
  "euro-circle": euroCircle,
  request,
  requests,
  "th-large": thLarge,
  time,
  user,
};
